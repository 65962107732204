import axios from 'axios';
import axiosRetry from 'axios-retry';
import store from '@/store/store';

export const setupAxiosRetry = () => {
  axiosRetry(axios, {
    retries: 5,
    retryDelay: (retryCount, error) => {
      const status = error.response?.status;

      if (status === 429) {
        const retryAfter = Number(error.response?.headers['retry-after']) || 2;
        return retryAfter * 1000;
      }

      if (status && status >= 500 || status === 408) {
        if (retryCount === 5) store.commit("setServerIssuesWarning", true);
        return 2000;
      }
      return 0;
    },
    retryCondition: (error) => {
      if (error.response) {
        return error.response?.status === 429 ||
        error.response?.status === 408 ||
        error.response?.status >= 500;
      }
      return false;
    },
  });
};